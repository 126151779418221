.tcList{
    /* height: 80vh; */
    overflow: auto;
    scrollbar-width: thin;
}

.tcList::-webkit-scrollbar{
    height: 7px;
    width: 7px;
}

.tcList::-webkit-scrollbar-thumb {
  background:#A6A6A6;
  border-radius: 20px;
}

.tcList::-webkit-scrollbar-track {
  border:2px solid #A6A6A6;
  border-radius: 20px;
}