@media (min-width: 768px) {
  .main-container {
    .dashboard-content {
      margin-left: 220px;
    }
  }
}
@media only screen and (max-width: 991px) {
  .login-form-detail {
    height: 100vh !important;
  }
}