$primary-color: #3c68c3;
$secondary-color: #2a304e;
$third-color: #232840;
$main-container-bg: #f7f9fc !important;
$sidebar-color: #d4d6db;
$text-color: #7a7d7e;
$text-secondary-color: #2a304e;
$font-regular: 'HelveticaNeue-Regular';
$font-medium: 'HelveticaNeue-Medium';
$font-bold: 'HelveticaNeue-Bold';
$font-light: 'HelveticaNeue-Light';
$base-color: #29304D;
$base-font-size: 16px;
$box-background: #fff;
$white-box-shadow: 0 0px 4px #00000029;
$box-padding: 20px;
$box-margin: 20px;
$border-radius: 6px;
$text-left: left;
$site-transition: all .3s;
$display-flex: flex;
$top-spacing: 35px;
$top-20: 20px;
$text-right: right;
