@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,700,700i");
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

.monthYearPicker {
  margin: auto;
  width: 400px;
  margin-top: 10px;
  border-radius: 15px;
  padding:15px;
  box-shadow:  0px 0px 10px 5px rgba(0, 0, 0, 0.2);
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

  .titled {
    padding: 10px 0px 10px 0px;
    margin: 5px 0px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: gray;
  }

.controls{
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 0px 10px 0px;
}

.monthPicker{
  margin:0px;

}

.month-name{
  display: flex;
align-items: center;
justify-content: space-around;
}

.un-picked{
  padding:5px 25px 5px 25px;
  margin:10px 0px 10px 0px;
  cursor: pointer;
  color: gray;
}

.picked{
  background-color: #3c68c3;
  padding:5px 25px 5px 25px;
  margin:10px 0px 10px 0px;
  border-radius: 20px;
  cursor: pointer;
  color:white
}

.selected-year{
 font-size: 20px;
 font-weight: bold; 
 color: gray;
}

.fa {
        margin: auto;
        cursor: pointer;
        font-size: 15px;
        color:gray
      }



  /* .year-picker {
    position: relative;
    width: 100%;
    background-color: cyan;
    margin-bottom: 20px;
  } */

    /* span {
      font-size: 25px;
      color: pink;
      text-align: center;
      width: 100%;
      display: block;
    } */

    /* .controls {
      position: absolute;
      right: 0;
      width: 80px;
      top: 0;
      height: 100%;
      display: flex;
    }



        .disabled {
          cursor: default;
          opacity: 0.3;
        }
       */
    
  

  /* .month-picker {
    position: relative;
    width: 100%;
  } */
/* 
    div {
      position: relative;
      width: 100%;
      margin: auto;
      display: block;
    }

      div {
        background-color: #ddd;
        color: cornflowerblue;
        height: 55px;
        margin: 10px;
        width: 20%;
        display: inline-flex;
        cursor: pointer;
      } */

        /* .selected {
          background-color: pink;
          color: #FFFFFF;
        } */

        /* span {
          margin: auto;
          font-size: 15px;
          font-style: normal;
        } */
