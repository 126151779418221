@import "../../assets/scss/variable.scss";

/*/////////////////monthSelector Start///////////////////*/
.monthSelector{        
  cursor: pointer;
  background: #F3F3F3;
  border-radius: .25rem;
  display: flex;
  overflow-X: auto;
  scrollbar-width:none;
}

.monthSelector::-webkit-scrollbar {
  height: 7px;
}

.monthSelector::-webkit-scrollbar-thumb {
  background:#A6A6A6;
  border-radius: 20px;
}

.monthSelector::-webkit-scrollbar-track {
  border:2px solid #A6A6A6;
  border-radius: 20px;
}
/*/////////////////monthSelector end///////////////////*/

.month{
    padding: 4px 10px 4px 10px;
    font-size: 12px;
    color: #5D6069;
    border-bottom: 1px solid #F3F3F3;
    width: 100%;
    text-align:center;
}

.activeMonth{
    border: 0.5px solid $secondary-color;
    border-radius: 5px;
}